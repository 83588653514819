import React from 'react'
import Img from 'gatsby-image'
import { v4 as uuidv4 } from 'uuid'

const ProjectItem = ({ post }) => {
  return (
    <a className="project is-block" href={`/projecten/${post.slug}`}>
      <div className="column-inner">
        {post.acf.img_project_overview.localFile ? (
          <Img
            fluid={
              post.acf.img_project_overview.localFile.childImageSharp.fluid
            }
            alt={post.acf.img_project_overview.alt_text}
            title={post.acf.img_project_overview.title}
          />
        ) : null}
        <div className="clearfix is-flex project-overlay">
          <div className="project-overlay-inner has-text-centered">
            <div className="project-name">{post.title}</div>
            <div className="line-between" />

            {post.categories
              ? post.categories.map(category => (
                  <div className="project-categorie" key={uuidv4()}>
                    {category.name}
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    </a>
  )
}

export default ProjectItem
